<template>
    <section class="rentWithdrawal" @click.stop="pickerclick">
        <div class="top-bar bg-white" style="justify-content: space-between;align-items: center">
            <div>
                时<span style="padding: 10px"></span>间 :<span style="padding: 10px"></span>
                <el-select ref="yearRef" v-model="year" filterable placeholder="请选择" size="small">
                    <el-option v-for="(item,index) in 100" :key="index" :label="index+2000" :value="index+2000"/>
                </el-select>
                <span style="padding: 10px">住房类型 :</span>
                <el-select ref="parentRef" v-model="parent" filterable placeholder="请选择" size="small">
                    <el-option label="全部" :value="0"/>
                    <el-option label="保障性租赁住房" :value="600210"/>
                    <el-option label="长租公寓" :value="600220"/>
                </el-select>
                <span style="padding: 5px"></span>
                <el-select ref="zijiRef" v-model="ziji" filterable placeholder="请选择" size="small">
                    <el-option v-loading="loading" label="全部" :value="0"/>
                    <el-option v-for="(item,index) in houseTypes" :key="index" :label="item.codeName" :value="item.code"/>
                </el-select>
            </div>
            <div>
                <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button size="small" @click="rentExport">导出</el-button>
                <span style="padding-left: 30px;font-size: 18px;">
<!--                    <el-link type="primary" :underline="false" style="color:#2C8BF7!important;">查看已导出列表</el-link>-->
                </span>
                <span style="padding: 15px;font-size: 18px;">
                    <el-link type="primary" :underline="false" style="color:#2C8BF7!important;" @click="handleReset">重置筛选条件</el-link>
                </span>
            </div>
        </div>
        <div style="display:flex;justify-content: space-between;">
            <div style="width: 49%;">
                <div>
                    <div class="title">实收租金</div>
                </div>
                <div style="margin-bottom: 30px;background-color: #FFF;border-radius: 10px;padding: 20px;">
                    <div id="thisYearECharts" style="height: 300px;"></div>
                </div>
            </div>
            <div style="width: 49%;">
                <div>
                    <div class="title">旧欠</div>
                </div>
                <div style="margin-bottom: 30px;background-color: #FFF;border-radius: 10px;padding: 20px;">
                    <div id="oldDebtECharts" style="height: 300px;"></div>
                </div>
            </div>
        </div>
        <div style="padding: 25px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="rentTableRef" :dataRequest="getBillRentList" :columns="rentTableColumn"
                       :query="formSearch" :height="300" show-summary :summary-method="getSummaries">
                <template slot="empty">
                    <el-empty/>
                </template>
            </r-e-table>
        </div>
    </section>
</template>

<script>
    import * as echarts from "echarts";
    import {rentTableColumn, eChartsOptions} from "@/views/bill-management/rent-withdrawal/data";
    import {getBillRentList, getBillRentLine, getBillRentJQline,rentExport} from "@/api/rent-withdrawal";
    import {downloadByData} from "@/utils/hooks/download";
    import { timeFormat, numberFormat } from "@custom/index";
    export default {
        name: "rentWithdrawal",
        components: {},
        data() {
            return {
                formSearch: {},
                year: (new Date()).getFullYear(),
                thisYearECharts: null,
                oldDebtECharts: null,
                eChartsOptions: eChartsOptions,
                rentTableColumn: rentTableColumn(this),
                parent: 0,
                ziji: 0,
                houseTypes: [],
                loading: false
            };
        },
        methods: {
            getBillRentList(params){
                let year = this.year;
                if (year === null || year === "") year = (new Date()).getFullYear();
                let code = this.ziji === 0 ? this.parent : this.ziji;
                params.year = year;
                params.code = code;
                return getBillRentList(params);
            },
            handleSearch(){
                this.$refs["rentTableRef"].pNumber = 1;
                this.$refs["rentTableRef"].getTableData();
                this.getBillRentLine();
                this.getBillRentJQline();
            },
            handleReset() {
                this.formSearch = {page: 1, pageSize: 10};
                this.parent = 0;
                this.ziji = 0;
                this.year = (new Date()).getFullYear();
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            eChartsInit(eChartsName,eChartsOptions) {
                let {...options} = eChartsOptions;
                this[eChartsName] = echarts.init(document.getElementById(eChartsName));
                this[eChartsName].setOption(options, true);
                window.addEventListener("resize", () => {
                    this[eChartsName].resize();
                });
            },
            getBillRentLine(){
                let year = this.year;
                if (year === null || year === "") year = (new Date()).getFullYear();
                let code = this.ziji === 0 ? this.parent : this.ziji;
                getBillRentLine({year,code}).then(res=>{
                    let {xList, yList} = res.info;
                    this.setOption("thisYearECharts",xList, yList);
                }).catch(err=>{});
            },
            getBillRentJQline(){
                let year = this.year;
                if (year === null || year === "") year = (new Date()).getFullYear();
                let code = this.ziji === 0 ? this.parent : this.ziji;
                getBillRentJQline({year,code}).then(res=>{
                    let {xList, yList} = res.info;
                    this.setOption("oldDebtECharts",xList, yList);
                }).catch(err=>{});
            },
            rentExport(){
                const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                let year = this.year;
                rentExport({year}).then(res=>{
                    downloadByData({data: res, filename: `租金回笼${timeFormat(new Date(),'yyyy-MM-dd-HH-mm-ss')}.xls`});
                    loading.close();
                }).catch(err => loading.close());
            },
            setOption(eChartsName,xList,yList){
                let {...eChartsOptions} = this.eChartsOptions;
                // 获取首个 ‘折线’ 数据，并将按照首个‘折线’数据的配置,装载‘折线’数据
                let legendData = {icon: 'roundRect', textStyle: {fontSize:15}};
                let legendDataArr = [];
                for (let item of yList) {
                    let {...data} = legendData;
                    data.name = item.name;
                    legendDataArr.push(data);
                }
                //填充‘折线’数据 主要是对样式的配置
                eChartsOptions.legend = {data: legendDataArr};
                //eCharts x轴配置
                eChartsOptions.xAxis.data = xList;
                //获取首个数据，并将按照首个数据的配置,装载数据
                let seriesData = {type: 'line',smooth:true};
                let seriesDataArr=[];
                for(let item of yList){
                    let {...data}=seriesData;
                    data.name = item.name;
                    data.data = item.data.map(a=>a/=100);
                    seriesDataArr.push(data);
                }
                //eCharts 填充数据
                eChartsOptions.series = seriesDataArr;
                this[eChartsName].setOption(eChartsOptions, true);
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (index === 4 || index === 7) {
                        sums[index] = '';
                        return;
                    }
                    const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) return prev + curr;
                            else return prev;
                        }, 0);
                        sums[index] = numberFormat(sums[index] / 100);
                    } else sums[index] = '';
                });
                return sums;
            },
          // 2024-4-18-新增点击空白关闭组件
          pickerclick(e){
            // console.log( this.itr)
            // this.itr = !this.itr;
            // if(this.itr){
            //   this.$refs['startAndEndDateRef'].hidePicker();
            //   this.$refs['billStartAndEndDateRef'].hidePicker();
            // }
            this.$refs.yearRef.blur()
            this.$refs.parentRef.blur()
            this.$refs.zijiRef.blur()
          },
          pickerclickitr(){
            this.itr = true;
            console.log(this.itr)
          },
        },
        async mounted() {
            this.eChartsInit("thisYearECharts",this.eChartsOptions);
            this.eChartsInit("oldDebtECharts",this.eChartsOptions);
            this.getBillRentLine();
            this.getBillRentJQline();
        },
        beforeDestroy() {
            window.removeEventListener("resize", () => {
                this.thisYearECharts.resize();
                this.oldDebtECharts.resize();
            });
        },
        watch: {
            async parent(value) {
                if (value !== 0) {
                    this.ziji = 0;
                    this.loading = true;
                    this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', value);
                    this.loading = false;
                } else {
                    this.ziji = 0;
                    this.houseTypes = [];
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .rentWithdrawal {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>